// Migrated
<template lang="pug">
ScreenWidthProvider.slides-behind-slider.mx-n2.mx-md-0(
  v-slot="{ isNarrower, isWider }"
  :class="computedClass"
)
  div(
    v-for="breakpoint in sortedComputedElementsPerSlide"
    :key="breakpoint.breakpoint"
  )
    Swiper.w-100.h-100.d-block(
      v-if="isWider(breakpoint.breakpoint) && (!breakpoint.nextBreakpoint || isNarrower(breakpoint.nextBreakpoint))"
      v-bind="swiperOptions"
      loading-skeleton-animation="skeleton-slides"
    )
      .row.px-0(
        v-for="(elementsGroup, groupIndex) in breakpoint.slides"
        :key="groupIndex"
      )
        .col(
          v-for="(element, slideIndex) in elementsGroup"
          :key="slideIndex"
          :class="columnClass"
        )
          component(:is="element")
</template>

<script>
import { SCREEN_WIDTH_SIZES } from '@packages/screen/constants/sizes.js'

export default defineNuxtComponent({
  props: {
    /**
     * @prop {'white' | 'light-turquoise'}
     */
    backdropColor: {
      type: String,
      default: 'white'
    },

    dynamicBullets: {
      type: Boolean,
      default: true
    },

    dotsType: {
      type: String,
      default: 'dark'
    },
    coverflowDepth: {
      type: Number,
      default: 425
    },
    swiperContainerStyle: {
      type: Object,
      default: () => ({
        margin: '0 -104%',
        width: 'unset !important'
      })
    },
    columnClass: {
      type: String,
      default: null
    },
    hasActivitiesCards: {
      type: Boolean,
      default: false
    },
    elementsPerSlide: {
      type: Object,
      default: () => ({
        [SCREEN_WIDTH_SIZES.xs]: 1,
        [SCREEN_WIDTH_SIZES.ipad]: 3,
      })
    }
  },

  data () {
    return {
      hydrated: false,
    }
  },

  computed: {
    swiperOptions () {
      return {
        slidesPerView: 3,
        dotsType: this.dotsType,
        dynamicBullets: this.dynamicBullets,
        loop: true,
        verticalCenteredSlides: true,
        onlyRenderVisibleSlides: true,
        preventSwiperUpdate: true,
        spaceBetween: 40,
        loadingSkeletonAspect: false,
        loadingSkeletonHeight: '425px',
        pagination: true,
        arrowsType: 'circular',
        arrowsSize: 'sm',
        arrowsColor: 'black',
        dotsAlign: 'outside',
        effect: 'coverflow',
        coverflowEffect: {
          rotate: 0,
          depth: this.coverflowDepth,
          shadows: true
        },

        breakpoints: {
          0: {
            navigation: false,
          },

          [SCREEN_WIDTH_SIZES.md]: {
            navigation: true,
            constantDisplayNavigation: false,
            arrowsAlign: 'inner-border'
          },

          [SCREEN_WIDTH_SIZES.xl]: {
            constantDisplayNavigation: true,
            arrowsAlign: 'outside-lg'
          },
        },

        swiperContainerStyle: this.swiperContainerStyle
      }
    },

    sortedComputedElementsPerSlide() {
      const slots = this.$slots.default?.()

      let slides = []

      slots.forEach((slot) => {
        // Vue seems to group components into a single component for some reason
        if (slot.ctx) {
          slides = [
            ...slides,
            ...slot.children
          ]
        } else {
          slides.push(slot)
        }
      })

      return Object.entries(this.elementsPerSlide || {})
        .map(([breakpoint, elements]) => [parseInt(breakpoint), elements])
        .sort(([a], [b]) => a - b)
        .map(([breakpoint, elements], index, array) => ({
          breakpoint,
          slides: chunkArray(slides.slice(0), elements, true),
          nextBreakpoint: array[index + 1]?.[0]
        }))
    },

    computedClass() {
      if (this.hasActivitiesCards) {
        return `slides-behind-slider__color-${this.backdropColor} slides-behind-slider__has-activities-cards`;
      } else {
        return `slides-behind-slider__color-${this.backdropColor} slides-behind-slider__default`;
      }
    },
  },

  mounted () {
    this.hydrated = !!process.browser
  },
})
</script>

<style lang="scss">
/*! purgecss start ignore */
.slides-behind-slider {

  &__default{
    clip-path: inset(-100px -6.52% -100px -6.46%);
  }

  &__has-activities-cards {
    clip-path: inset(-100px -13.8% -100px -12.92%);
  }

  &__color-white {
    .swiper-slide-shadow-coverflow {
      background: rgba(255, 255, 255, .85) !important;
      background-image: none !important;

      @media (max-width: $lg) {
        background: rgba(255, 255, 255, .75) !important;
        background-image: none !important;
      }
    }
  }

  &__color-light-turquoise {
    .swiper-slide-shadow-coverflow {
      background: rgba(236, 250, 255, .85) !important;
      background-image: none !important;

      @media (max-width: $lg) {
        background: rgba(236, 250, 255, .75) !important;
        background-image: none !important;
      }
    }
  }
}
/*! purgecss end ignore */
</style>
